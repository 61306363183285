import React from "react"
import { Layout, Parallax, NewsletterSection } from "@components/layout"
import { Breadcrumbs } from "@components/shared"

import { PostItem, BlogHero } from "@components/blog"

import { posts__wrapp } from "./styles/categories.module.scss"

const Posts = ({ posts }) => {
  return (
    <React.Fragment>
      <div className={posts__wrapp}>
        {posts.map((el, k) => (
          <PostItem {...el} key={k} />
        ))}
      </div>
    </React.Fragment>
  )
}

const SingleCategory = ({ pageContext }) => {
  const { posts, page } = pageContext
  console.log(page)
  return (
    <Layout seo={page.seo} siteMetaData={page.siteMetaData}>
      <BlogHero title={page.name} />
      <Breadcrumbs
        elements={[
          {
            label: "Blog",
            url: `/blog/`,
          },
          {
            label: page.name,
            url: `/blog/${page.slug}/`,
          },
        ]}
      />
      <Posts posts={posts} />
      <NewsletterSection />
    </Layout>
  )
}

export default SingleCategory
